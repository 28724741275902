.access-code-form .code-wrapper {
  border: 1px solid var(--grey-80);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
}

.access-code-form .code-wrapper input {
  border: none;
  box-sizing: content-box;
  color: var(--mid-blue);
  font-size: 28px;
  font-weight: 600;
  height: 28px;
  letter-spacing: 22px;
  line-height: 28px;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  -webkit-text-size-adjust: 100%;
}

.access-code-form input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.error-message {
  color: var(--pink);
  margin: 16px 0;
}

.access-code-form .primary-button {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  margin: 16px 0;
  padding: 12px 20px;
  width: 100%;
}

@media (min-width: 768px) {
  .access-code-form .code-wrapper {
    width: 500px;
  }

  .access-code-form .primary-button {
    width: auto;
  }

  .access-code-form .code-wrapper input {
    font-size: 52px;
    letter-spacing: 32px;
    line-height: 52px;
    height: 52px;
  }
}
