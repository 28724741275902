.primary-button {
  background-color: var(--mid-blue);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 12px;
  text-align: center;
  -webkit-appearance: none;
}

a.primary-button:visited {
  color: white;
}

.primary-button:disabled {
  background-color: var(--grey-80);
}
