html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

main {
  margin: 0 16px;
}

main h1 {
  margin: 40px 0 24px 0;
}

main p {
  margin: 16px 0;
}

.access-code-form {
  margin-top: 8px;
}

.access-code-form button:focus {
  outline: 0;
}

.devices-image {
  background-image: url('../../images/devices-image.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: block;
  height: 200px;
  margin: 64px 0 48px 0;
}

.certifications {
  margin: 16px 0 48px 0;
  padding: 8px;
}

.certifications h2 {
  color: var(--navy-blue);
  display: inline-block;
  width: 270px;
  margin-right: 16px;
}

.certifications .description {
  display: inline-block;
  max-width: 640px;
}

.cert-wrap {
  display: inline-block;
}

.certifications .description {
  margin: 18px 0 16px 0;
}

.cert,
.cert a {
  color: var(--grey-30);
}

.cert {
  display: inline-flex;
  margin-right: 32px;
}

.cert a,
.cert span {
  flex: 1 1 auto;
}

.cert .certification-icon {
  flex: 0 0 auto;
}

.datenschutz-cert-logo {
  background-image: url('../../images/ips-datenschutz-logo.svg');
}

.kbv-logo {
  background-image: url('../../images/kbv-logo.svg');
}

.certification-icon {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
  width: 24px;
}

@media (min-width: 768px) {
  main {
    margin: 0 auto;
    padding: 16px;
    max-width: 640px;
  }

  main h1 {
    margin: 0 0 24px 0;
  }

  .access-code-form .code-wrapper {
    max-width: 500px;
  }

  .access-code-form .primary-button {
    width: auto;
  }

  .devices-image {
    height: 444px;
  }

  .certifications h2 {
    padding-left: 16px;
  }

  .certifications .description:first-child {
    margin-top: 0;
  }

  .cert-wrap {
    padding-left: 32px;
  }
}

@media (min-width: 1200px) {
  main {
    margin: 0 auto;
    max-width: 800px;
    padding: 0;
  }

  .certifications {
    margin: 0 auto 48px auto;
    max-width: var(--max-content-width);
    padding: 0;
  }

  .certifications h2 {
    padding-left: 0;
  }

  .devices-image {
    height: 695px;
  }
}
